import React from "react";
import {GalleryStyle} from "./style";
import {GalleryFolder} from "./organism/folder";
import {Breadcrumbs, Stack, Typography} from "@mui/material";
import {Link} from "gatsby";

export const FolderList = ({parentSlug, folders}) => {
  return (
      <GalleryStyle>
        <Breadcrumbs aria-label="breadcrumb" separator={<Typography color={"primary"} fontSize={24}>/</Typography>}>
          <Link underline="hover" color="inherit" href={`/`} sx={{fontSize:24}}>
            <Typography fontSize={24}>Accueil</Typography>
          </Link>
          <Typography fontSize={24} color={"primary"}>Galerie</Typography>
        </Breadcrumbs>
        <Stack
            direction={{xs: "column", md:"row"}}
            mt={{xs:4, md:12}}
            spacing={2}
            justifyContent={"center"}
            alignItems={"center"}
        >
          {folders?.length && folders.map(folder => {
            return <GalleryFolder parentSlug={parentSlug} folder={folder}/>
          })}
        </Stack>
      </GalleryStyle>
  )
}