import React from "react";
import {Box, ButtonBase, Typography, useTheme} from "@mui/material";
import {FolderStyle} from "./style";
import {navigate} from "gatsby";

export const GalleryFolder = ({parentSlug, folder}) => {
  const theme = useTheme()
  return (
      <FolderStyle>
        <ButtonBase
          onClick={() => navigate(`/${parentSlug}/${folder.slug}`)}
        >
          <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={400}
              width={600}
              maxWidth={"100%"}
              bgcolor={theme.palette.primary.main}
          >
            <Typography textAlign={"center"} color={"text.secondary"} className={"folder-text"} fontSize={32}>{folder.name}</Typography>
          </Box>
        </ButtonBase>
      </FolderStyle>
  )
}