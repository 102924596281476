import React from 'react';
import {graphql} from "gatsby";
import {FolderList} from "../templates/gallery/folderList";

function GalleryPage({data}) {
  const {gallery} = data
  return (
      <FolderList parentSlug={gallery.slug} folders={gallery?.childrenFolder}/>
  )
}
export default GalleryPage;

export const query = graphql`
  query Gallery($name: String) {
    gallery(name: {eq: $name}) {
      id
      name
      slug
      childrenFolder {
        id
        name
        slug
      }
    }
  }
`
